import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header/Header';
import Nav from '../Nav/Nav';
import './Rules.css'
import { ReactComponent as Logo} from '../../pl-icon-mobile-final.svg';
import { ReactComponent as MenuIcon} from '../../menu-icon.svg';

const Rules = ({ user, logUserOut, throwBanner, toggleMobileMenu }) => {
  return(
  <div>
    <Header user={user}
            logUserOut={logUserOut}
            throwBanner={throwBanner}
            toggleMobileMenu={toggleMobileMenu}
    />
    <div className="Content">
      <Nav />
      <div className="Main">
        <div className="Container">
          <div className="Card">
            <div className="Mobile-header-bg">
              <div className="Mobile-header-wrapper">
                <div className="Mobile-pl-icon">
                  <Link to='/'>
                    <Logo />
                  </Link>
                </div>
                  <div className="Page-title-wrapper">
                  <p className="Page-title Rules">Rules</p>
                  <h1 className="Rules-title">Power List Rules</h1>
                </div>
                <div className="Mobile-menu-link" onClick={toggleMobileMenu}>
                  <span className="Mobile-menu-text">Menu</span>
                  <MenuIcon className="Mobile-menu-icon" />
                </div>
              </div>
            </div>
            <div className="Phablet-rules-bg">
              <ol className="Rules-list">
                <li className="Rule-item">
                  <h2>1. Complete 3+ Daily Tasks</h2>
                  <p>Enter at least three tasks every day. They should be tasks 
                    that move you forward, so checking your email doesn’t count.</p>
                </li>
                <li className="Rule-item">
                  <h2>2. Win Every Day</h2>
                  <p>Each day is an opportunity to make progress. 
                    Perfection may not be the result, but it should be the goal.</p>
                </li>
                <li className="Rule-item">
                  <h2>3. Build on Your Habits</h2>
                  <p>Once a task becomes a habit, replace it with a new task. 
                    Soon you'll be making even more progress with less effort.</p>
                </li>
                <li className="Rule-item">
                  <h2>Listen to the Podcast</h2>
                  <div className="Podcast-description">
                    <p>Listen to the podcast that inspired this application.</p>
                    <p><i>* Warning: Explicit language</i></p>
                  </div>
                    <iframe src="https://open.spotify.com/embed-podcast/episode/0CixOFj0oz7DovpqtoBnei" width="100%" height="232" frameBorder="0" allowtransparency="true" allow="encrypted-media" title="podcast-iframe"></iframe>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Rules;