import React from 'react';
import './DateNav.css';

const DateNav = ({ date, getDates, navItemsByDay, month, getMonths, navItemsByMonth }) => {  
  let navItem;
  let nextDate;
  let previous;
  let next;
  let disablePrevLink = "";
  let disableNextLink = "";
  if(navItemsByDay) {
    navItem = navItemsByDay;
    nextDate = getDates;
    previous = date.previous
    next = date.next;
  } else {
    navItem = navItemsByMonth;
    nextDate = getMonths;
    previous = month.previous;
    next = month.next;
    disableNextLink = navItemsByMonth.disableNextLink;
    disablePrevLink = navItemsByMonth.disablePrevLink;
  }
  
  if(navItem.current !== undefined) {
    return (
      <div className="Date-nav">
        <div id="Prev-date" className={`Change-date ${disablePrevLink}`} onClick={() => nextDate(previous)}>
          <i className="fas fa-caret-left Traverse-left"></i>
          <p className="Next-date">{ navItem.previous }</p>
        </div>
        <h1 className="Current-date">{ navItem.current }</h1>
        <div id="Next-date" className={`Change-date ${disableNextLink}`} onClick={() => nextDate(next)}>
          <p className="Next-date">{ navItem.next }</p>
          <i className="fas fa-caret-right Traverse-right"></i>
        </div>
      </div>
    );
  } else {
    return (<div className="Date-nav"><p>There was a problem fetching the date</p></div>);
  }
}

export default DateNav;