import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import './MobileMenu.css';
import { ReactComponent as Logo} from './powerlist-menu-icon.svg';
import DarkLogo from './powerlist-dark-final.svg';
import { ReactComponent as Lougout} from './logout-icon.svg';

const MobileMenu = ({ showMobileMenu, toggleMobileMenu, logUserOut }) => {
  if(showMobileMenu) {
    return (
      <div className={`Mobile-menu ${showMobileMenu ? 'Show-mobile-menu-bg' : ''}`} onClick={(e) => {
        if(e.target.className.split(' ')[0] === "Mobile-menu") { toggleMobileMenu() } }} >
        <div className={`Mobile-menu-content ${showMobileMenu ? 'Show-mobile-menu' : ''}`}>
          <Link to="/ " onClick={toggleMobileMenu}>
            <div className="Mobile-pl-logo">
              <img src={DarkLogo} className="Mobile-logo-svg" alt="Power List logo"/>
            </div>
          </Link>
          <ul className="Mobile-nav-list">
            <li className={`Mobile-link ${showMobileMenu ? 'Show-mobile-link' : ''}`} onClick={toggleMobileMenu}>
              <NavLink exact to="/" activeClassName="current-mobile-link"> 
              <Logo className="Mobile-nav-logo"/>
              <span> Power List </span>
              </NavLink>
            </li>
            <li className={`Mobile-link ${showMobileMenu ? 'Show-mobile-link' : ''}`} onClick={toggleMobileMenu}>
              <NavLink to="/stats" activeClassName="current-mobile-link">
                <i className="far fa-chart-bar Mobile-nav-icon"></i>
                <span>Stats</span>
              </NavLink>
            </li>
            <li className={`Mobile-link ${showMobileMenu ? 'Show-mobile-link' : ''}`} onClick={toggleMobileMenu}>
              <NavLink to="/rules" activeClassName="current-mobile-link">
                <i className="fas fa-balance-scale Mobile-nav-icon"></i>
                <span>Rules</span>
              </NavLink>
            </li>
            <li className={`Mobile-link ${showMobileMenu ? 'Show-mobile-link' : ''}`} onClick={toggleMobileMenu}>
              <NavLink to="/account" activeClassName="current-mobile-link"> 
                <i className="far fa-user-circle Dropdown-item Mobile-nav-icon"></i>
                <span>My Account</span>
              </NavLink>
            </li>
          </ul>
          <div className={`Mobile-logout ${showMobileMenu ? 'Show-mobile-link' : ''}`} onClick={() => { toggleMobileMenu(); logUserOut(); }}>
            <Lougout className="Logout-icon"/>
            <span>Log Out</span>
          </div>
        </div>
      </div>
    );
  } else {
    return(
      <span className="No-display">
        <div className="Mobile-menu"></div>
      </span>
    );
  }
}

export default MobileMenu;