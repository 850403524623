import React, { Component } from 'react';
import logo from '../powerlist-light-bg-final.svg';
import SignupForm from '../components/Forms/SignupForm';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      registerEmailAddress: '',
      registerPassword: '',
      showErrors: false,
      errors: []
    }
  }

  onFirstNameChange = (e) => {
    this.setState({firstName: e.target.value});
  }

  onEmailChange = (e) => {
    this.setState({registerEmailAddress: e.target.value});
  }

  onPasswordChange = (e) => {
    this.setState({registerPassword: e.target.value});
  }

  onSubmitRegistration = async () => {
    try {
      const { formatDate, isValidEmail, isValidPassword, isValidName, getAuthToken } = this.props;
      const name = isValidName(this.state.firstName);
      const email = isValidEmail(this.state.registerEmailAddress);
      const password = isValidPassword(this.state.registerPassword);
      if(name.isValid && email.isValid && password.isValid) {
        const response = await fetch(`${this.props.url}/user`, {
            method: 'post', 
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              firstName: name.value,
              email: email.value,
              password: password.value,
              dateJoined: formatDate(new Date())
            })
        });
        const responseData = await response.json();
        if(response.status === 400) {
          throw new Error(responseData);
        }
        await localStorage.setItem('pl_auth_token', JSON.stringify(responseData.token));
        await getAuthToken();
        this.props.throwBanner('Success', 'Account Created');
      } else {
        const errorList = [name, email, password];
        const errors = [];
        errorList.forEach(field => {
          if(!field.isValid) {
            errors.push(field.message);
          }
        });
        this.setState({ showErrors: true });
        this.setState({ errors });
      }
    } catch (e) {
      this.props.throwBanner('Alert', e.message);
    }
  }
  

  render = () => {
    return(
      <div>
        <div className="Form-container">
          <div className="Card">
          <img src={logo} alt="Power List Logo" className="Signup-login-logo"/>
          <SignupForm 
            showErrors={this.state.showErrors} 
            errors={this.state.errors} 
            onFirstNameChange={this.onFirstNameChange} 
            onEmailChange={this.onEmailChange} 
            onPasswordChange={this.onPasswordChange}
            onSubmitRegistration={this.onSubmitRegistration}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default Signup;