import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import ProtectedRoute from './components/Routers/ProtectedRoute';
import PublicRoute from './components/Routers/PublicRoute';
import Login from './containers/Login';
import Signup from './containers/Signup';
import Confirmation from './containers/Confirmation';
import ResetPassword from './containers/ResetPassword';
import Home from './containers/Home';
import Stats from './containers/Stats';
import Profile from './containers/Profile';
import Rules from './components/Rules/Rules';
import Footer from './components/Footer/Footer';
import Banner from './components/Banner/Banner';
import MobileMenu from './components/MobileMenu/MobileMenu';
import validator from 'validator';
import './App.css';

const initialUserState = {
  firstName: '',
  email: '',
  joined: '',
  token: ''
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      user: { initialUserState },
      winThreshold: 3,
      allTasks: [],
      notifyBanner: {
        show: false, 
        type: '', 
        msg: ''},
      showMobileMenu: false,
      url: ''
    }
  }

  componentDidMount = async () => {
    // If no token in state-
    if(!this.state.user.token) {
      this.getAuthToken();
    }
    if(!this.state.url) {
      this.currUrl();
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    // If user token added to state
    if(this.state.user.token !== prevState.user.token && this.state.isAuthenticated) {
      await this.loadUser();
      await this.getAllTasks();
    }
  }

  // set url
  currUrl = () => {
    if(process.env.NODE_ENV === 'development') {
      this.setState({ url: process.env.REACT_APP_DEV_URL });
    } else {
      this.setState({ url: process.env.REACT_APP_PROD_URL });
    }
  }

  // User Session

  getAuthToken = async () => {
    // Return Auth token if available
    try {
      const token = await JSON.parse(localStorage.getItem('pl_auth_token'));
      if(!token) {
        throw new Error('No token found');
      }
      const user = Object.assign({}, this.state.user);
      user.token = token;
      this.setState({ isAuthenticated: true });
      this.setState({ user });
      return true;
    } catch (e) {
      this.logUserOut();
      return false;
    }
  }

  loadUser = async () => {
      // If loaduser() called but no auth token found
      if(!this.state.user.token) {
        this.getAuthToken();
      }
      try {
        const token = this.state.user.token;
        const response = await fetch(`${this.state.url}/user`, {
          method: 'get',
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          })
        });
        // Case if login token is expired
        this.checkSessionStatus(response);
        if(response.status === 500) {
          throw new Error('We\'re having some trouble with the server right now');
        }
        const user = await response.json();
        const thisUser = Object.assign({}, this.state.user);
        thisUser.firstName = user.first_name;
        thisUser.email = user.email;
        thisUser.joined = user.date_joined;
        this.setState({ user: thisUser });
      } catch (e) {
        this.throwBanner('Alert', e.message);
      }
  }

  checkSessionStatus = async (res) => {
    try {
      if(res.status === 401) {
        await this.logUserOut();
        throw new Error(res.statusText);
      }
    } catch (e) {
      this.throwBanner('Alert', "Sesssion expired, please log in");
      // console.error(e);
    }
  }

  logUserOut = async () => {
    if(localStorage.getItem('pl_auth_token')) {
      await localStorage.removeItem('pl_auth_token');
    }
    await this.setState({ isAuthenticated: false });
    await this.setState({ user: { initialUserState } });
    await this.setState({ allTasks: [] });
    const from = this.props.history.location.pathname;
    const redirectFrom = ['/', '/stats', '/rules', '/account'];
    if(redirectFrom.includes(from)) {
      this.props.history.push('/login');
    }
  }

  // Updating Task State

  getAllTasks = async () => {
    try {
      const token = this.state.user.token;
      if(!token) {
        this.getAuthToken();
      }
      const response = await fetch(`${this.state.url}/tasks`, {
        method: 'get',
        headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      })
      });
      this.checkSessionStatus(response);
      if (response.status === 500) {
        throw new Error('Something went wrong on our server');
      }
      const tasks = await response.json();
      this.setState({ allTasks: tasks });
    } catch (e) {
      this.throwBanner('Alert', e.message);
    }
  }

  addToAllTasks = (task) => {
    const allTasks = [...this.state.allTasks];
    allTasks.push(task);
    this.setState({allTasks: allTasks});
  }

  updateAllTasks = (task) => {
     const allTasks = [...this.state.allTasks];
     allTasks.forEach(oldTask => {
       if(oldTask.id === task.id) {
         oldTask = task;
       }
     });
     this.setState({allTasks: allTasks});
  }

  deleteFromAllTasks = (task) => {
    let allTasks = [...this.state.allTasks];
    allTasks = allTasks.filter(prevTask => prevTask.id !== task.id);
    this.setState({allTasks: allTasks});
  }

  // Login & Signup Validation

  isValidEmail = (email) => {
    const userInput = {
      type: 'email',
      isValid: null,
      value: '',
      message: ''
    }
    // Check if email is blank
    userInput.value = validator.trim(email);
    if(!userInput.value) {
      userInput.isValid = false;
      userInput.message = 'Email is required';
      return userInput;
    }
    // Check if email address is < 100 chars
    if(userInput.value.length > 100) {
      userInput.isValid = false;
      userInput.message = 'Email cannot be longer than 100 characters';
      return userInput;
    }
    // Check if valid email address
    userInput.isValid = validator.isEmail(userInput.value);
    if(!userInput.isValid) {
      userInput.message = 'Please enter a valid email address';
      return userInput;
    }
    // Normalize email
    userInput.value = validator.normalizeEmail(userInput.value, {
      all_lowercase: true,
      gmail_remove_subaddress: true,
      outlookdotcom_remove_subaddress: true,
      yahoo_remove_subaddress: true,
      icloud_remove_subaddress: true
    });
    userInput.message = 'Success'
    return userInput;
  }


  isValidPassword = (password) => {
    const userInput = {
      type: 'password',
      isValid: null,
      value: '',
      message: ''
    }
    // Check if password field empty
    userInput.value = validator.trim(password);
    if(!userInput.value) {
      userInput.isValid = false;
      userInput.message = 'Password is required';
      return userInput;
    }
    // Check if password >= 8 AND <= 32 chars
    if(userInput.value.length < 8 || userInput.value.length > 32) {
      userInput.isValid = false;
      userInput.message = 'Password must be between 8 and 32 characters'
      return userInput;
    }
    userInput.value.toString();
    userInput.isValid = true;
    userInput.message = 'Success';
    return userInput;
  }

  isValidName = (name) => {
    const userInput = {
      type: 'name',
      isValid: null,
      value: '',
      message: ''
    }
    // Check if Name > 0 && <= 50 chars
    userInput.value = validator.trim(name);
    if(userInput.value < 1 || userInput.value.length > 50) {
      userInput.isValid = false;
      userInput.message = 'Name field is required';
      return userInput;
    }
    userInput.value.toString();
    userInput.isValid = true;
    userInput.message = 'Success';
    return userInput;
  }

  // Notifications
  throwBanner = (type, msg) => {
    this.clearBanners();
    this.setState({ 
      notifyBanner: {
        show: true,
        type: type,
        msg: msg 
    }});

    setTimeout(() => {
      this.clearBanners();
    }, 3500);
  }

  clearBanners = () => {
    this.setState({ 
      notifyBanner: {
        show: false,
        type: '',
        msg: ''
      }
    });
  }

  // Mobil menu
  toggleMobileMenu = () => {
    this.setState({ showMobileMenu: !this.state.showMobileMenu });
  }

  // General Formatting

  formatDate = (date) => {
    const thisDate = new Date(date);
    const thisMonth = thisDate.getMonth()+1;
    const thisDay = thisDate.getDate();
    const thisYear = thisDate.getFullYear();
    return `${thisMonth}-${thisDay}-${thisYear}`;
  }

  render = () => {
    return (
      <div className="App">
        <Banner banner={this.state.notifyBanner}/>
        <MobileMenu 
          showMobileMenu={this.state.showMobileMenu} 
          toggleMobileMenu={this.toggleMobileMenu} 
          logUserOut={this.logUserOut}
          />
        <Switch>
          <PublicRoute path='/login' isAuthenticated={this.state.isAuthenticated} component={() => 
            <Login 
              throwBanner={this.throwBanner}
              getAuthToken={this.getAuthToken} 
              isValidEmail={this.isValidEmail}
              isValidPassword={this.isValidPassword}
              url={this.state.url}
              /> } />
          <PublicRoute path='/signup' isAuthenticated={this.state.isAuthenticated} component={() => 
            <Signup 
              throwBanner={this.throwBanner}
              isValidEmail={this.isValidEmail}
              isValidPassword={this.isValidPassword}
              isValidName={this.isValidName}
              getAuthToken={this.getAuthToken}
              formatDate={this.formatDate}
              url={this.state.url}
              /> } />
          <PublicRoute path='/confirmation/new' isAuthenticated={this.state.isAuthenticated} component={() => 
            <Confirmation 
              throwBanner={this.throwBanner}
              isValidEmail={this.isValidEmail}
              history={this.props.history}
              url={this.state.url}
              /> } />
          <PublicRoute path='/password/' isAuthenticated={this.state.isAuthenticated} component={() => 
            <ResetPassword 
              throwBanner={this.throwBanner}
              isValidEmail={this.isValidEmail}
              isValidPassword={this.isValidPassword}
              history={this.props.history}
              url={this.state.url}
              /> } />
          <ProtectedRoute path='/stats' isAuthenticated={this.state.isAuthenticated} component={() =>
            <Stats 
              user={this.state.user}
              throwBanner={this.throwBanner}
              logUserOut={this.logUserOut}
              checkSessionStatus={this.checkSessionStatus}
              tasks={this.state.allTasks} 
              formatDate={this.formatDate}
              winThreshold={this.state.winThreshold}
              toggleMobileMenu={this.toggleMobileMenu}
            /> } />
          <ProtectedRoute path='/account' isAuthenticated={this.state.isAuthenticated} component={() => 
            <Profile 
              throwBanner={this.throwBanner}
              user={this.state.user}
              logUserOut={this.logUserOut}
              checkSessionStatus={this.checkSessionStatus}
              loadUser={this.loadUser}
              isValidEmail={this.isValidEmail}
              isValidPassword={this.isValidPassword}
              isValidName={this.isValidName}
              toggleMobileMenu={this.toggleMobileMenu}
              history={this.props.history}
              url={this.state.url}
              />
          }/>
           <ProtectedRoute path='/rules' isAuthenticated={this.state.isAuthenticated} component={() => 
            <Rules  
              throwBanner={this.throwBanner}
              user={this.state.user}
              logUserOut={this.logUserOut}
              toggleMobileMenu={this.toggleMobileMenu}
              />
          }/>
          <Route path='/' progressBar={true} isAuthenticated={this.state.isAuthenticated}>
            {this.state.isAuthenticated
            ?
            <Home 
              isAuthenticated={this.state.isAuthenticated}
              throwBanner={this.throwBanner}
              user={this.state.user} 
              logUserOut={this.logUserOut}
              checkSessionStatus={this.checkSessionStatus}
              tasks={this.state.allTasks}
              getAuthToken={this.getAuthToken}
              addToAllTasks={this.addToAllTasks}
              updateAllTasks={this.updateAllTasks}
              deleteFromAllTasks = {this.deleteFromAllTasks}
              winThreshold={this.state.winThreshold}
              formatDate={this.formatDate}
              toggleMobileMenu={this.toggleMobileMenu}
              url={this.state.url}
              />
            :
            <Login 
              throwBanner={this.throwBanner}
              getAuthToken={this.getAuthToken} 
              isValidEmail={this.isValidEmail}
              isValidPassword={this.isValidPassword}
              url={this.state.url}
              />
                }
          </Route>
        </Switch>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
