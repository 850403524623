import React from 'react';
import { Link } from 'react-router-dom';

const LoginForm = ({ onEmailChange, onPasswordChange, onLoginSubmit }) => {
  return(
    <>
      <form>
        <fieldset>
          <legend>Log In</legend>
          <div className="Form-element">
            <label className="Form-label" htmlFor="email-address">Email Address</label>
            <input className="Form-input" type="email" name="email-address" id="email-address" placeholder="john.doe@mail.com" onChange={onEmailChange} autoComplete="on"/>
          </div>
          <div className="Form-element">
            <div className="Form-label-wrapper">
              <label className="Form-label" htmlFor="password">Password</label>
              <Link to='password/new' className="Form-label-link" tabIndex="-1">
                <span>Forgot?</span>
              </Link>
            </div>
            <input className="Form-input" type="password" name="password" id="password" placeholder="Enter password" onChange={onPasswordChange} autoComplete="off"/>
          </div>
          <div className="Form-element">
            <Link to="/">
              <input className="Form-input" type="submit" value="Log In" onClick={onLoginSubmit}/>
            </Link>
          </div>
          <div className="Change-login-signup">
            <Link to='/signup' className="Form-link Signup-link">
              <p>Sign Up</p>
            </Link>
          </div>
        </fieldset>
      </form>
      <div className="Change-login-signup Confirmation">
        <Link to='/confirmation/new' className="Form-link">
          <p>Resend Confirmation Link</p>
        </Link>
      </div>
    </>
  )
}

export default LoginForm;