import React from 'react';
import { Link } from 'react-router-dom';

const ConfirmationForm = ({onEmailChange, onSubmitConfirmation }) => {

  return (
    <>
      <form>
        <fieldset>
          <legend>Resend Confirmation</legend>
          <div className="Form-element">
            <label className="Form-label" htmlFor="email-address">Email Address</label>
            <input className="Form-input" type="email" name="email-address" id="email-address" placeholder="jane.doe@mail.com" onChange={onEmailChange}/>
          </div>
          <div className="Form-element">
            <input className="Form-input" type="submit" value="Resend Confirmation" onClick={(e) => {onSubmitConfirmation(); e.preventDefault();}}/>
          </div>
        </fieldset>
      </form>
      <div className="Change-login-signup">
        <Link to='/login' className="Form-link">
          <i className="fas fa-long-arrow-alt-left"></i>
          <p>Back to Log In</p>
        </Link>
      </div>
    </>
  )
}

export default ConfirmationForm;