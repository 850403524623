import React from 'react';
import './TaskInput.css';
import validator from 'validator';
import EmptyIcon from './empty-task-icon.svg';
import EmptyMobileIcon from './empty-state-mobile-icon.svg'

const TaskInput = ({ onTaskSubmit, onInputChange, editState, clearEditState, onUpdateTask, taskInput, taskList }) => {
  const activeBtn = validator.trim(taskInput).length > 0 ? 'Active' : '';
  if(editState) {
    return (
      <>
        <div className="Input-component">
          <input className="Task-input" type="text" placeholder="Enter a task" onChange={onInputChange}/>
          <button className={`Task-btn Save`} type="submit" onClick={onUpdateTask}><span className={`Task-btn-text Save`}>Update</span></button>
          <button className="Task-btn Cancel" onClick={clearEditState}>Cancel</button>
        </div>
        <div className="Empty-state-div"></div>
      </>
    )
  } else {
    return (
      <>
        <div className="Input-component">
          <input className="Task-input" type="text" placeholder="Enter a task" onChange={onInputChange}/>
          <button className={`Task-btn ${activeBtn}`} type="submit" onClick={onTaskSubmit} ><span className={`Task-btn-text ${editState ? 'Edit-active' : ''}`}>+ Add Task</span></button>
        </div>
        <div className="Empty-state-div">
          {!taskList.length ? <img src={EmptyIcon} className="Empty-state-icon" alt="arrow-icon"/> : <span className="Empty-state-msg"></span>}
          {!taskList.length ? <img src={EmptyMobileIcon} className="Empty-state-icon Mobile" alt="arrow-icon"/> : <span className="Empty-state-msg"></span>}
          {!taskList.length ? <span className="Empty-state-msg">Please Enter a Task</span> : <span className="Empty-state-msg"></span>}
        </div>
      </>
    )
  }
  
}

export default TaskInput;