import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';

import './Dropdown.css';

class Dropdown extends Component {

  handleClickOutside = e => {
    const clickOnParent = Array.from(e.target.classList).includes('Dropdown-link');
    const clickOnChild = Array.from(e.target.parentElement.classList).includes('Dropdown-link');
    if(this.props.state.dropdownOpen && !(clickOnParent || clickOnChild)) {
      this.props.toggleDropdown();
    }
  }

  
  render = () => {
    const displayDropdown = this.props.state.dropdownOpen ? "Show" : "";
    const dropdownLinks = this.props.state.dropdownLinks;
    return (
      <div className={`Dropdown-component ${displayDropdown}`}>
        <ul className="Dropdown-list">
          {dropdownLinks.map(link => (
            <Link to={link.path} className="Dropdown-link" key={link.title} onClick={link.onClick}>
              <li className="Dropdown-item-text" key={link.title}>{link.title}</li>
            </Link>))}
        </ul>
      </div>
    )
  }
}

export default onClickOutside(Dropdown);