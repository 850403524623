import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
      <div className="Footer">
        <div className="Footer-content">
          <p className="Footer-item">Built by <span role="img" aria-label="Robot-emoji">🤖</span>'s at Light Mattr</p>
          <a href="https://lightmattr.com/?utm_source=powerlist&utm_medium=app&utm_campaign=demo" target="_blank" rel="noopener noreferrer" className="LM-site-link">
            <p>View Website</p>
          </a>
      </div>
    </div>)
}

export default Footer;