import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header/Header';
import ProfileInfo from '../components/ProfileInfo/ProfileInfo';
import validator from 'validator';
import { ReactComponent as Logo} from '../pl-icon-mobile-final.svg';
import { ReactComponent as MenuIcon} from '../menu-icon.svg';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            emailAddress: '',
            newPassword: '',
            currentPassword: ''
        }
    }

    onNameChange = (e) => {
        this.setState({firstName: e.target.value});
      }
    
    onEmailChange = (e) => {
        this.setState({emailAddress: e.target.value});
    }

    onNewPasswordChange = (e) => {
        this.setState({newPassword: e.target.value});
    }

    onCurrentPasswordChange = (e) => {
        this.setState({currentPassword: e.target.value});
    }

    onSubmitProfileUpdate = async () => {
        const { throwBanner } = this.props;
        try {
            const { firstName, emailAddress, newPassword, currentPassword } = this.state;
            const { user, isValidEmail, isValidPassword, isValidName, loadUser } = this.props;
            const name = isValidName(firstName);
            const email = isValidEmail(emailAddress);
            if(!email.isValid && email.value.length > 0) {
                throw new Error(email.message);
            }
            const password = isValidPassword(currentPassword);
            if(!password.isValid) {
                throw new Error(password.message);
            }
            const candidatePassword = validator.trim(newPassword);
            let validatedCandidate = {};
            if(candidatePassword.length === 0) {
                validatedCandidate.isValid = true;
                validatedCandidate.value = candidatePassword;
                validatedCandidate.message = 'No value provided';
            } else {
                validatedCandidate = await isValidPassword(candidatePassword);
                if(!validatedCandidate.isValid) {
                    throw new Error('New ' + validatedCandidate.message);
                }
            }
            if(password.isValid && validatedCandidate.isValid) {
              const response = await fetch(`${this.props.url}/user`, {
                  method: 'put', 
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${user.token}`
                    },
                  body: JSON.stringify({
                    firstName: name.value,
                    email: email.value,
                    newPassword: validatedCandidate.value,
                    currentPassword: password.value
                  })
              });
              this.props.checkSessionStatus(response);
              if(response.status === 400) {
                  throw new Error('Password does not match our records');
              }
              if(response.status === 403) {
                throw new Error('The email address you\'ve entered already has an account');
              }
              if(response.status === 406) {
                throw new Error('Invalid password length');
              }
              if(response.status === 500) {
                throw new Error('Unable to update user. Please try again.');
              }
              await loadUser();
              throwBanner('Success', 'Your Account Has Been Updated');
            }
        } catch (e) {
            throwBanner('Alert', e.message);
            console.error(e);
        }
      }

    onDeleteAccount = async () => {
    if(window.confirm('Are you sure you want to delete your account forever? All data will be lost.')) {
        try {
            await fetch(`${this.props.url}/user`, {
                method: 'delete', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.props.user.token}`
                    }
            });
            await localStorage.removeItem('pl_auth_token');
            this.props.logUserOut();
            this.props.throwBanner('Alert', 'You\'re account has been deleted')
          } catch (e) {
              console.error(e);
          }
        }
    }

    
    render = () => {
        return(
            <div>
                <Header 
                    user={this.props.user}
                    throwBanner={this.props.throwBanner}
                    logUserOut={this.props.logUserOut}
                    toggleMobileMenu={this.props.toggleMobileMenu}
                    />
                <div className="Form-container Profile">
                    <div className="Card">
                        <div className="Mobile-header-bg">
                            <div className="Mobile-header-wrapper">
                                <div className="Mobile-pl-icon">
                                    <Link to='/'>
                                        <Logo />
                                    </Link>
                                </div>
                                <div id="Mobile-page-title" className="Page-title-wrapper">
                                    <p className="Page-title">My Account</p>
                                </div>
                                <div className="Mobile-menu-link" onClick={this.props.toggleMobileMenu}>
                                    <span className="Mobile-menu-text">Menu</span>
                                    <MenuIcon className="Mobile-menu-icon" />
                                </div>
                            </div>
                        </div>
                        <h1 className="Profile-title"><i className="far fa-user-circle Profile-icon"></i></h1>
                        <ProfileInfo 
                            user={this.props.user}
                            onNameChange={this.onNameChange}
                            onEmailChange={this.onEmailChange}
                            onNewPasswordChange={this.onNewPasswordChange}
                            onCurrentPasswordChange={this.onCurrentPasswordChange}
                            onSubmitProfileUpdate={this.onSubmitProfileUpdate}/>
                    </div>
                    <div className="Delete-account" onClick={() => this.onDeleteAccount()}>
                        <p>Delete Account Forever</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Profile;