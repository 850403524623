import React from 'react';
import './Checkbox.css';

const Checkbox = ({ onCheck, checked, date }) => {
    if(new Date(date) > new Date()) {
        return(
            <span className="Checkbox-wrapper">
                <input className="Hidden-checkbox" type="checkbox" readOnly checked={checked}></input>
                <span className="check"></span>
            </span>
        )
    } else {
        return(
            <span className="Checkbox-wrapper">
                <input className="Hidden-checkbox" type="checkbox" checked={checked} onChange={onCheck}></input>
                <span className="check" onClick={onCheck}></span>
            </span>
        )
    }
}

export default Checkbox;