import React from 'react';
import './Banner.css';

const Banner = ({ banner }) => {
    return(
      <div className={`Banner ${banner.show ? "Active" : ""} ${banner.type}`}>
        <span>{banner.msg}</span>
      </div>
    );
}

export default Banner;