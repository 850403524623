import React from 'react';
import { Link } from 'react-router-dom';

const SignupForm = ({ showErrors, errors, onFirstNameChange, onEmailChange, onPasswordChange, onSubmitRegistration }) => {

  return (
    <>
      <form>
        <fieldset>
          <legend>Sign Up</legend>
          {showErrors
            ?
            <ul className="Signup-error-banner">
              {errors.map((error, i) => {
                return(<li key={i}>{error}</li>)
              })}
            </ul>
            :
            <ul className="No-error-banner"></ul>
          }
          <div className="Form-element">
            <label className="Form-label" htmlFor="first-name">First Name</label>
            <input className="Form-input" type="text" name="first-name" id="first-name" placeholder="Jane" onChange={onFirstNameChange}/>
          </div>
          <div className="Form-element">
            <label className="Form-label" htmlFor="email-address">Email Address</label>
            <input className="Form-input" type="email" name="email-address" id="email-address" placeholder="jane.doe@mail.com" onChange={onEmailChange}/>
          </div>
          <div className="Form-element PW">
            <label className="Form-label" htmlFor="password">Password</label>
            <input className="Form-input" type="password" name="password" id="Signup-password" placeholder="Enter password" onChange={onPasswordChange} autoComplete="off"/>
            <p>Must be between 8 and 32 characters</p>
          </div>
          <div className="Form-element">
            <p className="Terms-text">By clicking <strong>"Sign Up"</strong> you agree to our
              <a href="https://mypowerlist.com/terms" className="Form-link legal" target="_blank" rel="noopener noreferrer"><strong> Terms of Service </strong></a>and 
              <a href="https://mypowerlist.com/privacy" className="Form-link legal" target="_blank" rel="noopener noreferrer"><strong> Privacy </strong></a>agreements
            </p>
            <input className="Form-input" type="submit" value="Sign Up" onClick={(e) => {onSubmitRegistration(); e.preventDefault();}}/>
          </div>
        </fieldset>
      </form>
      <div className="Change-login-signup">
        <p className="Change-form-text">Already Have an Account?</p> 
        <Link to='/login' className="Form-link">
          <p>Log In</p>
        </Link>
      </div>
    </>
  )
}

export default SignupForm;