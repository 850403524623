import React, { Component } from 'react';
import './CommentList.css';
import { ReactComponent as CommentIcon} from './comment-icon.svg';


class CommentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentComments: []
        }
    }

    componentDidMount = async () => {
        await this.filterComments();
        await this.props.scrollToBottom();
    }

    componentDidUpdate = async (prevProps) => {
        if(prevProps !== this.props) {
            await this.filterComments();
        }
    }

    filterComments = async () => {
        const allComments = [...this.props.comments];
        const comments = allComments.filter(comment => this.props.task.id === comment.taskId);
        comments.sort((a, b) => a.commentIndex > b.commentIndex ? 1 : -1);
        this.setState({ currentComments: comments });
    }
        
    render = () => {
        const { onEditComment, onDeleteComment } = this.props
        if(this.state.currentComments.length === 0) {
            return(
                <div className="Comment Empty">
                    <CommentIcon className="Empty-comment-icon"/>
                    <p id="Empty-comment-message">Add a Note to this Task</p>
                </div>
            )
        } else {
            return(
                this.state.currentComments.slice(0).reverse().map(comment => {
                    return(
                        <div id={`Comment-${comment.commentIndex}`} className="Comment" key={comment.commentIndex}>
                            <p>{comment.data}</p>
                            <div className="Comment-footer">
                                <p>{comment.timestamp}</p>
                                <div className="Comment-icons">
                                    <div className="Icon-wrapper" onClick={onEditComment}>
                                        <i className="far fa-edit Task-icon"></i>
                                    </div>
                                    <div className="Icon-wrapper">
                                        <i className="far fa-trash-alt Task-icon" onClick={onDeleteComment}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            );
        }
    }
}

export default CommentList;