import React, { Component } from 'react';
import ReactModal from 'react-modal';
import CommentList from '../components/CommentList/CommentList';
import CommentInput from '../components/CommentInput/CommentInput';
import validator from 'validator';

class Comments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTaskId: '',
            commentInput: '',
            comments: [],
            editState: {
                active: false,
                currentCommentId: ''
            }
        }
    }

    componentDidMount = async () => {
        if(this.props.isAuthenticated) {
            const comments = await this.getComments();
            if(comments) {
                await this.calculateCommentTotals();
            }
        }
    }

    componentDidUpdate = async (prevProps) => {
        // if user token updates, load current comments
        if(prevProps.token !== this.props.token && this.props.isAuthenticated) {
            await this.getComments();
            await this.calculateCommentTotals();
        }
        // If current date changes get current comments
        if(prevProps.date !== this.props.date && this.props.isAuthenticated) {
            await this.getComments();
            await this.calculateCommentTotals();
        }
    }

    calculateCommentTotals = () => {
        // returns object with taskId key and int value
        const comments = [...this.state.comments];
        let taskTotals = {};
        comments.forEach(comment => {
            // taskId: count
            if(!taskTotals[comment.taskId]) {
                taskTotals[comment.taskId] = 1;
            } else {
                taskTotals[comment.taskId] = taskTotals[comment.taskId] + 1
            }
        });
        // Send comment count to parent state
        this.props.getCommentTotalByTask(taskTotals);
    }

    // Scroll to bottom of comment list
    scrollToBottom = async () => {
        let commentList = document.getElementById('Comment-list');
        if(commentList !== null) {
            commentList.scrollTop = commentList.scrollHeight;
        }
    }

    getComments = async () => {
        // Return list of comments for current date
        try {
            const currDate = this.props.date;
            const token = this.props.token;
            if(!currDate.includes('NaN') && token) {
                const response = await fetch(`${this.props.url}/comments/${currDate}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                      }
                });
                this.props.checkSessionStatus(response);
                const comments = await response.json();
                if(response.status === 500) {
                    throw new Error('Unable to save changes');
                }
                this.setState({ comments });
            }
        } catch (e) {
            this.props.throwBanner('Alert', e.message);
        }
    }

    onInputChange = (e) => {
        this.setState({commentInput: e.target.value});
        document.querySelector('.Comment-input').onkeydown = (e) => {
            const { editState } = this.state;
            if(e.keyCode === 13 && e.metaKey && !editState.active) {
                this.onSubmitNewComment();
            } else if (e.keyCode === 13 && e.metaKey && editState.active) {
                this.onUpdateComment();
            } 
        }
    }

    formatTimestamp = () => {
        const today = new Date();
        const day = today.getDate();
        const month = today.toLocaleString('default', { month: 'short' });
        const year = today.getFullYear();
        let hour = today.getHours();
        let minute = today.getMinutes();
        const meridiem = hour >=12 ? 'PM' : 'AM';
        hour = hour % 12;
        hour = hour ? hour : 12;
        minute = minute < 10 ? '0' + minute : minute;

        const timeStamp = `${month} ${day}, ${year} at ${hour}:${minute} ${meridiem}`;
        return timeStamp;
    }

    onSubmitNewComment = async () => {  //have used this method a lot
        try {
            // Generating comment index
            let maxIndex = 0; 
            this.state.comments.forEach(comment => {
                if(comment.commentIndex >= maxIndex) {
                    maxIndex = comment.commentIndex + 1;
                }
            })
            const numComments = this.state.comments.length + 1;
            const index = maxIndex > numComments ? maxIndex : numComments;
            // Get timestamp
            const timeStamp = this.formatTimestamp();
            // format comment request
            const comment = {
                taskId: this.props.task.id,
                taskDate: this.props.task.taskDate,
                commentIndex: index,
                data: this.state.commentInput,
                timestamp: timeStamp
            }
            // Get current token
            const token = this.props.token;
            // Check input values
            if(this.isValidComment(comment).valid) {
                // send request
                const response = await fetch(`${this.props.url}/comments`, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(comment)
                });
                this.props.checkSessionStatus(response);
                if(response.status !== 200) {
                    throw new Error('Unable to save your comment right now');
                }
                // handle response
                const newComment = await response.json();
                if(!newComment) {
                    throw new Error('Unable to register new comment');
                }
                const comments = [...this.state.comments];
                comments.push(newComment);
                // set new comments list
                this.setState({ comments });
                this.setState({ commentInput: '' });
                // run post update processes
                this.calculateCommentTotals();
                let input = document.querySelector('.Comment-input');
                input.value = '';
                input.focus();  
                this.scrollToBottom();
            } else {
                throw new Error('Please enter a valid comment');
            }
        } catch (e) {
            this.props.throwBanner('Alert', e.message);
            // throw banner
        }
    }

    // Edit comment
    onEditComment = (e) => {
        let parentElementId = e.currentTarget.parentElement.parentElement.parentElement.id;
        parentElementId = parentElementId.split('-')[1];
        this.state.comments.forEach(comment => {
            if(`${comment.commentIndex}` === parentElementId) {
              const input = document.querySelector('.Comment-input');
              input.value = comment.data;
              input.focus();
              this.setState({commentInput: comment.data});
              this.setState({editState: {active: true, currentCommentId: comment.id}});
            }
          });

    }

    onUpdateComment = async () => {
        try {
            const token = this.props.token;
            let comments = [...this.state.comments]
            let commentToUpdate = comments.filter(comment => comment.id === this.state.editState.currentCommentId)[0];
            if(validator.trim(this.state.commentInput).length > 0) {
                commentToUpdate.data = this.state.commentInput;
                const response = await fetch(`${this.props.url}/comments`, {
                    method: 'put',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(commentToUpdate)
                });
                this.props.checkSessionStatus(response);
                if(response.status !== 200) {
                    throw new Error('Unable to update comment right now');
                }
                const commentFromDb = await response.json();
                comments.forEach(comment => {
                    if(comment.id === commentFromDb.id) {
                        comment.data = commentFromDb.data;
                    }
                });
                this.setState({ comments });
                this.onClearEditState();
            } else {
                throw new Error('Unable to submit a blank comment');
            }
        } catch (e) {
            this.onClearEditState();
            this.props.throwBanner('Alert', e.message);
        }
    }

    onClearEditState = () => {
        const input = document.querySelector('.Comment-input');
        input.value = '';
        this.setState({commentInput: ''});
        this.setState({editState: {active: false, currentCommentId: ''}});
    }
    
    // delete comment
    onDeleteComment = async (e) => {
        try {
            if(window.confirm('Are you sure you want to delete this comment?')) {
                const token = this.props.token;
                let parentElementId = e.currentTarget.parentElement.parentElement.parentElement.parentElement.id;
                let comments = [...this.state.comments];
                const commentToDelete = comments.filter(comment => `Comment-${comment.commentIndex}` === parentElementId)[0];
                const response = await fetch(`${this.props.url}/comments`, {
                    method: 'delete',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(commentToDelete)
                });
                this.props.checkSessionStatus(response);
                if(response.status !== 200) {
                    throw new Error('Unable to delete this comment');
                }
                const deletedComment = await response.json();
                comments = comments.filter(comment => comment.id !== deletedComment.id);
                this.setState({ comments });
                this.calculateCommentTotals();
                this.props.throwBanner('Success', 'Comment deleted');
            }
        } catch (e) {
            this.props.throwBanner('Alert', e.message);
        }
    }
    
    isValidComment = (comment) => {
        const commentValid = {
          taskId: false,
          taskDate: false,
          commentIndex: false,
          data: false,
          timestamp: false
        }
        // check taskId is INT
        if(typeof comment.taskId === "number") {
            commentValid.taskId = true;
          }
        // Check length of comment date
        if(comment.taskDate.length >= 8 && comment.taskDate.length <= 10) {
          commentValid.taskDate = true;
        }
        // Check commentIndex is INT
        if(typeof comment.commentIndex === "number") {
          commentValid.commentIndex = true;
        }
        // Check data length
        comment.data = validator.trim(comment.data);
        if(comment.data.length > 0) {
          commentValid.data = true;
        }
        // Check timestamp length
        if(comment.timestamp.length <= 50) {
          commentValid.timestamp = true;
        }
        // Check valid fields
        if(commentValid.taskId && commentValid.taskDate && commentValid.commentIndex && commentValid.data && commentValid.timestamp) {
          return {valid: true, commentValid};
        }
        return {valid: false, commentValid};
      }


    render = () => {
        ReactModal.setAppElement('#root');
        return (
            <ReactModal 
                isOpen={this.props.showModal} 
                onRequestClose={this.props.handleCloseModal}
                className={'Comment-modal'} 
                overlayClassName={'Modal-overlay'}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                >
                <div className="Task-title">
                    <h1>{`${this.props.task.data}`}</h1>
                    <div className="Comment-close-icon" onClick={() => {this.props.handleCloseModal(); this.onClearEditState();}}>
                        <i className="fas fa-times"></i>
                    </div>
                </div>
                <div id="Comment-list" className="Comment-list">
                    <CommentList 
                        task={this.props.task}
                        comments={this.state.comments}
                        onEditComment={this.onEditComment}
                        onDeleteComment={this.onDeleteComment}
                        scrollToBottom={this.scrollToBottom}
                        />
                </div>
                <div className="Comment-add">
                    <CommentInput 
                        onInputChange={this.onInputChange} 
                        commentInput={this.state.commentInput}
                        editState={this.state.editState.active}
                        onSubmitNewComment={this.onSubmitNewComment}
                        onUpdateComment={this.onUpdateComment}
                        onClearEditState={this.onClearEditState}
                        />
                    <p className="Comment-submit-instruction">Cmd/Windows + Enter to Save</p>
                </div>
            </ReactModal> 
        );
    }
}

export default Comments;