import React from 'react';
import './Dashboard.css';

const Dashboard = ({ dashboardStats }) => {
  const { daysWon, taskMetrics, winLoss } = dashboardStats;

  return (
    <>
      <div className="Stats">
        <div className="Metric-container">
          <p className="Stat-title">Tasks Completed</p>
          <p className="Stat">{taskMetrics.tasksComplete}</p>
          <p className="Stat-detail">{taskMetrics.completionRate}% Completion Rate</p>
        </div>
        <div className="Metric-container">
          <p className="Stat-title">Days Won</p>
          <p className="Stat">{daysWon.numDays}</p>
          <p className="Stat-detail">{`${daysWon.winRate}% Win Rate`}</p>
        </div>
        <div className="Metric-container">
          <p className="Stat-title">Win/Loss</p>
          <p id="Monthly-outcome" className="Stat Default-outcome">{winLoss.outcome}</p>
          <p className="Stat-detail">{winLoss.message}</p>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
