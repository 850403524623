import React from 'react';
import './ProgressBar.css';

const ProgressBar = ({ taskMsg }) => {
  return(
    <div className="Progress-bar">
      <div className="Bar-bg"><div className="Progress"></div></div>
      <div className="Tasks-left-container">
        <i className={`fas fa-check-circle Tasks-complete-icon ${taskMsg === 'You Won the Day' ? 'Win' : ''}`}></i>
        <p className={`Tasks-left ${taskMsg === 'You Won the Day' ? 'Win' : ''}`}>{taskMsg}</p>
      </div>
    </div>
  );
}

export default ProgressBar;