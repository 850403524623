import React, { Component } from 'react';
import logo from '../powerlist-light-bg-final.svg';
import RequestPasswordLink from '../components/Forms/RequestPasswordLink';
import ResetPasswordForm from '../components/Forms/ResetPasswordForm';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetEmailAddress: '',
      resetPassword: ''
    }
  }

  onEmailChange = (e) => {
    this.setState({ resetEmailAddress: e.target.value });
  }

  onPasswordChange = (e) => {
    this.setState({ resetPassword: e.target.value });
  }

  onSendNewPasswordLink = async () => {
    try {
      let userEmail = this.state.resetEmailAddress;
      userEmail = this.props.isValidEmail(userEmail);
      if(!userEmail.isValid) {
        throw new Error('Please enter a valid email');
      }
      const response = await fetch(`${this.props.url}/user/password/reset`, {
        method: 'post', 
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          email: userEmail.value
        })
      });
      const responseData = await response.json();
      if(response.status !== 200) {
        throw new Error(responseData);
      }
      await this.props.throwBanner('Success', responseData);
      this.props.history.push('/login');
    } catch (e) {
      this.props.throwBanner('Alert', e.message);
      console.log(e)
    }
  }

  onUpdatePassword = async (token) => {
    try {
      let newPassword = this.state.resetPassword;
      newPassword = this.props.isValidPassword(newPassword);
      if(!newPassword.isValid) {
        throw new Error(newPassword.message);
      }
      const response = await fetch(`${this.props.url}/user/password/update`, {
        method: 'post', 
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          token: token,
          password: newPassword.value
        })
      });
      if(response.status === 200) {
        await this.props.throwBanner('Success', 'Password Updated');
        this.props.history.push('/login');
      }
      if(response.status === 400 || response.status === 500) {
        throw new Error(response.statusText);
      }
    } catch (e) {
      this.props.throwBanner('Alert', e.message);
    }
  }
  

  render = () => {
    return(
      <div>
        <div className="Form-container">
          <div className="Card">
          <img src={logo} alt="Power List Logo" className="Signup-login-logo"/>
          {this.props.history.location.pathname.includes('/password/update')
          ? <ResetPasswordForm onPasswordChange={this.onPasswordChange} onUpdatePassword={this.onUpdatePassword}/>
          : <RequestPasswordLink onEmailChange={this.onEmailChange} onSendNewPasswordLink={this.onSendNewPasswordLink}/>
          }
          </div>
        </div>
      </div>
    )
  }
}

export default ResetPassword;