import React from 'react';
import { Link } from 'react-router-dom';
import './ProfileInfo.css';

const ProfileInfo = ({ user, onNameChange, onEmailChange, onNewPasswordChange, onCurrentPasswordChange, onSubmitProfileUpdate }) => {
    return(
        <div className="Phablet-profile-bg">
            <form>
                <fieldset>
                    <legend className="Profile-legend">My Account</legend>
                    <div className="Form-element">
                        <label className="Form-label" htmlFor="first-name">First Name</label>
                        <input className="Form-input" type="text" name="first-name" id="first-name" placeholder={`${user.firstName}`} onChange={onNameChange} autoComplete="off"/>
                    </div>
                    <div className="Form-element">
                        <label className="Form-label" htmlFor="email-address">Email Address</label>
                        <input className="Form-input" type="email" name="email-address" id="email-address" placeholder={`${user.email}`} onChange={onEmailChange} autoComplete="off"/>
                    </div>
                    <div className="Form-element">
                        <label className="Form-label" htmlFor="password">New Password <i>(leave blank to keep same password)</i></label>
                        <input className="Form-input" type="password" name="password" id="new-password" placeholder="Enter New Password (between 8 and 32 characters)" onChange={onNewPasswordChange} autoComplete="off"/>
                    </div>
                    <div className="Form-element">
                        <label className="Form-label" htmlFor="password">Current Password* <i>(Required to confirm changes)</i></label>
                        <input className="Form-input" type="password" name="password" id="old-password" placeholder="Enter Current Password" onChange={onCurrentPasswordChange} autoComplete="off"/>
                    </div>
                    <div className="Form-element">
                        <input className="Form-input" type="submit" value="Update" onClick={(e) => {e.preventDefault(); onSubmitProfileUpdate();}}/>
                    </div>
                    <div className="Form-element" >
                        <Link to="/" className="Back-btn">
                            <i className="fas fa-long-arrow-alt-left Back-arrow"></i>
                            <p>Back to Power List</p>
                        </Link>
                    </div>
                </fieldset>
            </form>
        </div>
    );
}

export default ProfileInfo;