import React, { Component } from 'react';
import logo from '../powerlist-light-bg-final.svg';
import ConfirmationForm from '../components/Forms/ConfirmationForm';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmEmailAddress: ''
    }
  }

  onEmailChange = (e) => {
    this.setState({confirmEmailAddress: e.target.value});
  }

  onSubmitConfirmation = async () => {
    try {
      let userEmail = this.state.confirmEmailAddress;
      userEmail = this.props.isValidEmail(userEmail);
      if(!userEmail.isValid) {
        throw new Error('Please enter a valid email');
      }
      const response = await fetch(`${this.props.url}/user/confirmation/new`, {
        method: 'post', 
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          email: userEmail.value
        })
      });
      const responseData = await response.json();
      if(response.status !== 200) {
        throw new Error(responseData);
      }
      await this.props.throwBanner('Success', 'New confirmation link sent');
      this.props.history.push('/login');
    } catch (e) {
      this.props.throwBanner('Alert', e.message);
    }
  }
  

  render = () => {
    return(
      <div>
        <div className="Form-container">
          <div className="Card">
          <img src={logo} alt="Power List Logo" className="Signup-login-logo"/>
            <ConfirmationForm onEmailChange={this.onEmailChange} onSubmitConfirmation={this.onSubmitConfirmation}/>
          </div>
        </div>
      </div>
    )
  }
}

export default Signup;