import React from 'react';
import { Link } from 'react-router-dom';

const ResetPasswordForm = ({onPasswordChange, onUpdatePassword }) => {
  const token = window.location.search.replace('?token=', '');
  return (
    <>
      <form>
        <fieldset>
          <legend>Reset Password</legend>
          <div className="Form-element PW">
            <label className="Form-label" htmlFor="password">Enter New Password</label>
            <input className="Form-input" type="password" name="password" id="Signup-password" placeholder="Enter password" onChange={onPasswordChange} autoComplete="off"/>
            <p>Must be between 8 and 32 characters</p>
          </div>
          <div className="Form-element">
            <input className="Form-input" type="submit" value="Update Password" onClick={(e) => {onUpdatePassword(token); e.preventDefault();}}/>
          </div>
        </fieldset>
      </form>
      <div className="Change-login-signup">
        <Link to='/login' className="Form-link">
          <i className="fas fa-long-arrow-alt-left"></i>
          <p>Back to Log In</p>
        </Link>
      </div>
    </>
  )
}

export default ResetPasswordForm;