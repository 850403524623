import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from '../Dropdown/Dropdown';
import logo from './pl-icon-final.svg';
import './Header.css';
import { ReactComponent as MenuIcon} from './menu-icon.svg';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      dropdownLinks: [{title: "My Account", path: "/account"},
                      {title: "Log Out", path: "/", onClick: () => { this.props.logUserOut(); this.props.throwBanner('Message', 'Logged Out') }}]
    }
  }

  toggleDropdown = () => {
    const toggleState = this.state.dropdownOpen ? false : true;
    this.setState({dropdownOpen: toggleState});
  }

  render = () => {
    const { user } = this.props;
    return(
      <header>
        <div className="Header-container">
          <Link to="/">
            <img src={logo} className="PL-logo" alt="Power List Logo"/>
          </Link>
          <div className="Dropdown-menu">
            <div className="Dropdown-link" onClick={this.toggleDropdown}>
              <i className="far fa-user-circle Dropdown-item"></i>
              <p className="Dropdown-item">{user.firstName}</p>
              <i className="fas fa-angle-down"></i>
            </div>
            <Dropdown state={this.state} toggleDropdown={this.toggleDropdown}/>
          </div>
          <div className="Tablet-menu" onClick={this.props.toggleMobileMenu}>
            <p className="Dropdown-item">Menu</p>
            <MenuIcon />
          </div>
        </div>
      </header>
    )
  }
}

export default Header;