import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route { ...rest } render={(props) => (
      !rest.isAuthenticated
      ?
      <Component {...props} />
      :
      <Redirect to='/'  />      
    )}/>
)}

export default PublicRoute;