import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header/Header';
import Nav from '../components/Nav/Nav';
import DateNav from '../components/DateNav/DateNav';
import ProgressBar from '../components/ProgressBar/ProgressBar';
import TaskInput from '../components/TaskInput/TaskInput';
import TaskList from '../components/TaskList/TaskList';
import validator from 'validator';
import Comments from './Comments';
import { ReactComponent as Logo} from '../pl-icon-mobile-final.svg';
import { ReactComponent as MenuIcon} from '../menu-icon.svg';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: {},
      navItemsByDay: {
        current: '',
        previous: '',
        next: ''
      },
      taskInput: '',
      taskList: [],
      tasksComplete: 0,
      taskMsg: '',
      editState: {
        active: false,
        currentTaskId: null},
      commentModal: {
        showModal: false,
        task: {}
      },
      commentTotals: {}
    }
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  
  componentDidMount = async () => {
    if(this.props.isAuthenticated) {
      await this.getDates();
      await this.setNavItems();
      await this.getTasks();
      await this.calculateProgress();
      await this.updateProgressBar();
      await this.calculateRemainingTasks();
      const viewWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      const input = document.querySelector('.Task-input');
      if(!this.state.taskList.length && input !== null && viewWidth > 650) {
        input.focus();
      }
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    // on task updates
    if(this.props.tasks !== prevProps.tasks && this.props.isAuthenticated) {
      await this.getTasks();
      await this.calculateProgress();
      await this.updateProgressBar();
      await this.calculateRemainingTasks();
      await this.disableCheckboxes();
    }
    // on date change
    if(this.state.date !== prevState.date && this.props.isAuthenticated) {
      await this.setNavItems();
      await this.getTasks();
      await this.calculateProgress();
      await this.updateProgressBar();
      await this.calculateRemainingTasks();
      await this.disableCheckboxes();
      const viewWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      const input = document.querySelector('.Task-input');
      if(!this.state.taskList.length && input !== null && viewWidth > 650) {
        input.focus();
      }
    }
  }

  getDates = (date) => {
    let currDate;
    if(date === undefined) {
      currDate = new Date();
    } else {
      currDate = new Date(date);
    }
    const nextDate = new Date(currDate);
    const prevDate = new Date(currDate);
    nextDate.setDate(currDate.getDate() + 1);
    prevDate.setDate(currDate.getDate() - 1);
    this.setState({date: {
      current: currDate,
      next: nextDate,
      previous: prevDate
    }});
    if(this.state.editState.active) {
      this.clearEditState();
    }
  }

  setNavItems = () => {
    if(this.state.date.current !== undefined) {
      const { date } = this.state;
      const day = date.current.getDate();
      const month = date.current.toLocaleString('default', { month: 'short' });
      const year = date.current.getFullYear();
      const prevDay = date.previous.getDate();
      const prevMonth = date.previous.toLocaleString('default', { month: 'short' });
      const nextDay = date.next.getDate();
      const nextMonth = date.next.toLocaleString('default', { month: 'short' });
      const currDateString = `${month} ${day}, ${year}`;
      const prevDateString = `${prevMonth} ${prevDay}`;
      const nextDateString = `${nextMonth} ${nextDay}`;
      this.setState({navItemsByDay: 
        {current: currDateString, 
         previous: prevDateString, 
         next: nextDateString}})
    }
  }

  getTasks = () => {
    const { tasks } = this.props;
    const currDate = this.props.formatDate(this.state.date.current);
    if(tasks.length > 0) {
      const currentTasks = tasks.filter(task => task.taskDate === currDate);
      this.setState({taskList: currentTasks});
    }
  }

  calculateProgress = () => {
    let progress = 0;
    this.state.taskList.forEach(task => {
      if(task.isComplete) {
        progress += 1;
      }
    });
    // progress bar active state (no tasks checked)
    if(!progress && this.state.taskList.length >= this.props.winThreshold) {
      progress = 0.1;
      this.setState({ tasksComplete: progress })
    }
    // check progress bar active state
    if(!progress || this.state.taskList.length < this.props.winThreshold) {
      this.setState({tasksComplete: 0});
    } else {
      const numTasks = this.state.taskList.length;
      progress = progress / numTasks * 100;
      this.setState({tasksComplete: progress});
    }
  }

  updateProgressBar = () => {
    const progressBar = document.querySelector('.Progress');
    if(progressBar !== null) {
      progressBar.style.width = `${this.state.tasksComplete}%`;
    }
  }

  calculateRemainingTasks = () => {
    const numTasks = this.state.taskList.length;
    const taskLimit = this.props.winThreshold;
    const tasksNeeded = taskLimit - numTasks;
    const tasksComplete = Math.round(this.state.tasksComplete/100 * numTasks);
    const incompleteTasks = numTasks - tasksComplete;
    let msg;
    if(numTasks < taskLimit) {
      msg = `Please add ${tasksNeeded} more task${tasksNeeded === 1 ? '' : 's'}`;
    } else if(tasksComplete < numTasks) {
      msg = `${incompleteTasks} Task${incompleteTasks === 1 ? '' : 's'} Remaining`;
    } else if(tasksComplete === numTasks) {
      msg = 'You Won the Day';
    }
    this.setState({ taskMsg: msg });
  }

  isValidTask = (task) => {
    const taskValid = {
      taskDate: false,
      taskIndex: false,
      data: false,
      isComplete: false
    }
    // Check length of task date
    if(task.taskDate.length >= 8 && task.taskDate.length <= 10) {
      taskValid.taskDate = true;
    }
    // Check taskIndex is INT
    if(typeof task.taskIndex === "number") {
      taskValid.taskIndex = true;
    }
    // Check task data length
    task.data = validator.trim(task.data);
    if(task.data.length > 0) {
      taskValid.data = true;
    }
    // Check task isComplete is BOOL
    if(typeof task.isComplete === "boolean") {
      taskValid.isComplete = true;
    }
    if(taskValid.taskDate && taskValid.taskIndex && taskValid.data && taskValid.isComplete) {
      return true;
    }
    return taskValid;
  }

  onInputChange = (e) => {
    this.setState({taskInput: e.target.value});
    document.querySelector('.Task-input').onkeydown = (e) => {
      const { editState, taskInput } = this.state;
      if(e.keyCode === 13 && !editState.active && validator.trim(taskInput) !== '')  {
        this.onTaskSubmit();
      } else if(e.keyCode === 13 && editState.active && validator.trim(taskInput) !== '') {
        this.onUpdateTask();
      }
    }
  }

  onTaskSubmit = async () => {
    try {
      if(validator.trim(this.state.taskInput) === '') {
        document.querySelector('.Task-input').focus();
      }
      const { formatDate, addToAllTasks } = this.props;
      const currDate = formatDate(this.state.date.current);
      const token = this.props.user.token;
      let taskIndex = 1;
      this.state.taskList.forEach(task => {
        if(task.taskIndex >= taskIndex) {
          taskIndex = task.taskIndex + 1;
        }
        });
      const task = {taskDate: currDate, 
                    taskIndex: taskIndex,
                    data: validator.trim(this.state.taskInput),
                    isComplete: false};
      if(this.isValidTask(task) === true) {
        const response = await fetch(`${this.props.url}/tasks`, {
          method: 'post', 
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(task)
        });
        this.props.checkSessionStatus(response);
        if(response.status === 400) {
          throw new Error('Unable to save task to our server');
        }
        const taskData = await response.json();
        addToAllTasks(taskData);
        this.setState({taskInput: ''});
        document.querySelector('.Task-input').value = '';
      } else {
        throw new Error('Please enter valid task');
      }
    } catch (e) {
      document.querySelector('.Task-input').focus();
    }
  }

  disableCheckboxes = () => {
    if(new Date(this.state.date.current) > new Date()) {
      const checkboxes = document.querySelectorAll('.Hidden-checkbox');
      checkboxes.forEach(checkbox => {
        checkbox.setAttribute('disabled', '');
      });
    }
  }

  onCheckedTask = async (e) => {
    try {
      const target = e.target.previousSibling;
      const parentElementId = e.target.parentElement.parentElement.parentElement.id;
      const taskList = [...this.state.taskList];
      const token = this.props.user.token;
      let updatedTask;
      taskList.forEach(task => {
        if(`Task-${task.taskIndex}` === parentElementId && !target.checked) {
          task.isComplete = true;
          updatedTask = task;
        } else if(`Task-${task.taskIndex}` === parentElementId && target.checked) {
          task.isComplete = false;
          updatedTask = task;
        }
      });
      let response = await fetch(`${this.props.url}/tasks`, {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(updatedTask)
      });
      this.props.checkSessionStatus(response);
      if(response.status === 500) {
        throw new Error('Unable to save changes');
      }
      let data = await response.json();
      await this.props.updateAllTasks(data);
    } catch (e) {
      this.props.throwBanner('Alert', e.message);
    }
  }

  onEditTask = (e) => {
    const { taskList } = this.state;
    const parentElementId = e.currentTarget.parentElement.parentElement.id;
    taskList.forEach(task => {
      if(`Task-${task.taskIndex}` === parentElementId) {
        const input = document.querySelector('.Task-input');
        input.value = task.data;
        input.focus();
        this.setState({taskInput: task.data});
        this.setState({editState: {active: true, currentTaskId: task.id}});
      }
    });
  }

  onUpdateTask = async () => {
    try {
      const taskList = [...this.state.taskList];
      const token = this.props.user.token;
      if(validator.trim(this.state.taskInput).length < 1) {
        this.clearEditState();
        throw new Error('Task cannot be blank');
      }
      let updatedTask;
      taskList.forEach(task => {
        if(task.id === this.state.editState.currentTaskId) {
          task.data = this.state.taskInput;
          updatedTask = task;
        }
      });
      const response = await fetch(`${this.props.url}/tasks`, {
            method: 'put',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(updatedTask)
          });
      this.props.checkSessionStatus(response);
      if(response.status === 500) {
        throw new Error('Unable to save changes. Please try again later.');
      }
      const newTask = await response.json();
      this.props.updateAllTasks(newTask);
      this.clearEditState();
    } catch (e) {
      this.props.throwBanner('Alert', e.message);
    }
  }

  clearEditState = () => {
    this.setState({editState: {active: false, currentTaskId: ''}});
    this.setState({taskInput: ''});
    document.querySelector('.Task-input').value = '';
  }

  onDeleteTask = async (e) => {
    if(window.confirm('Are you sure you want to delete this task?')){
      try {
        const parentElementId = e.currentTarget.parentElement.parentElement.id;
        const token = this.props.user.token;
        let deletedTask;
        this.state.taskList.forEach(task => {
          if(`Task-${task.taskIndex}` === parentElementId) {
            deletedTask = task;
          }
        });
        const response = await fetch(`${this.props.url}/tasks`, {
              method: 'delete',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify(deletedTask)
            });
        this.props.checkSessionStatus(response);
        if(response.status === 500 || response.status === 404) {
          throw new Error('Unable to delete task');
        }
        const noTask = await response.json();
        this.props.deleteFromAllTasks(noTask);
        this.props.throwBanner('Success', 'Task deleted');
      } catch (e) {
        this.props.throwBanner('Alert', e.message);
      }
    }
  }

  // COMMENTS MODAL
  getCommentTotalByTask = (totals) => {
    this.setState({ commentTotals: totals });
  }

  // Open modal
  handleOpenModal = (e) => {
    let parentElementId = e.currentTarget.parentElement.parentElement.id;
    let selectedTask;
    this.state.taskList.forEach(task => {
      if(`Task-${task.taskIndex}` === parentElementId) {
        selectedTask = task;
      }  
    })
    this.clearEditState();
    this.setState({ commentModal: { showModal: true, task: selectedTask}});
  }

  // Close modal
  handleCloseModal = () => {
    // document.querySelector('.ReactModal__Overlay--after-open').style.opacity = "0";
    this.setState({ commentModal: { showModal: false, task: {}}});
  }
  
  
  render = () => {
    const { formatDate, user } = this.props;

    return (
      <div id="home">
        <Header 
          user={user} 
          logUserOut={this.props.logUserOut}
          throwBanner={this.props.throwBanner}
          toggleMobileMenu={this.props.toggleMobileMenu}
          />
        <Comments 
          isAuthenticated={this.props.isAuthenticated}
          throwBanner={this.props.throwBanner}
          showModal={this.state.commentModal.showModal}
          checkSessionStatus={this.props.checkSessionStatus}
          token={this.props.user.token}
          date={formatDate(this.state.date.current)}
          task={this.state.commentModal.task}
          handleCloseModal={this.handleCloseModal}
          getCommentTotalByTask={this.getCommentTotalByTask}
          url={this.props.url}
          />
        <div className="Content">
          <Nav />
          <div className="Main">
            <div className="Container">
              <div className="Card">
                <div className="Mobile-header-bg">
                <div className="Mobile-header-wrapper">
                  <div className="Mobile-pl-icon">
                    <Link to='/'>
                      <Logo />
                    </Link>
                  </div>
                  <div className="Page-title-wrapper">
                    <p className="Page-title">Power List</p>
                  </div>                  
                  <div className="Mobile-menu-link" onClick={this.props.toggleMobileMenu}>
                    <span className="Mobile-menu-text">Menu</span>
                    <MenuIcon className="Mobile-menu-icon" />
                  </div>
                </div>
                <DateNav 
                  date={this.state.date}
                  navItemsByDay={this.state.navItemsByDay}
                  getDates={this.getDates} 
                  clearEditState={this.clearEditState}
                  />
                <ProgressBar taskMsg={this.state.taskMsg}/>
                </div>
                <div className="Phablet-task-list-bg">
                  <TaskInput 
                    onInputChange={this.onInputChange}
                    taskList={this.state.taskList}
                    onTaskSubmit={this.onTaskSubmit} 
                    editState={this.state.editState.active} 
                    clearEditState={this.clearEditState}
                    onUpdateTask={this.onUpdateTask}
                    taskInput={this.state.taskInput}
                    />
                  <TaskList 
                    taskList={this.state.taskList} 
                    date={formatDate(this.state.date.current)} 
                    onCheckedTask={this.onCheckedTask}
                    onEditTask={this.onEditTask}
                    onDeleteTask={this.onDeleteTask}
                    handleOpenModal={this.handleOpenModal}
                    commentTotals={this.state.commentTotals}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;