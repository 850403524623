import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component,  path, ...rest }) => (
    <Route { ...rest } component={(props) => (
      rest.isAuthenticated
      ?
      <Component {...props} />
      :
      <Redirect to='/login'  />
    )}/>
)

export default ProtectedRoute;