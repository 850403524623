import React from 'react';
import './TaskHistory.css';

const TaskHistory = ({ state }) => {
  if(state !== null) {
    const taskHistoryDates = Object.keys(state.taskHistory);
    const { taskHistory } = state;
    return (
      <div className="Task-history">
        {taskHistoryDates.slice(0).reverse().map(day => (
          <div className="Task-summary" key={day}>
            <p className="Summary-item">{taskHistory[day].date}</p>
            <div className="Summary-stats">
              <p className="Summary-item">{`${taskHistory[day].complete}/${taskHistory[day].numTasks}`}</p>
              <div className={`Date-outcome ${taskHistory[day].outcome}`}><p>{taskHistory[day].outcome}</p></div>
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return(<><p>Could not find task history</p></>);
  }
}

export default TaskHistory;