import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Header from '../components/Header/Header';
import Nav from '../components/Nav/Nav';
import Dashboard from '../components/Dashboard/Dashboard';
import TaskHistory from '../components/TaskHistory/TaskHistory';
import DateNav from '../components/DateNav/DateNav';
import { ReactComponent as Logo} from '../pl-icon-mobile-final.svg';
import { ReactComponent as MenuIcon} from '../menu-icon.svg';


class Stats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      month: {},
      navItemsByMonth: {},
      taskHistoryStartDate: '',
      dashboardStats: {
        taskMetrics: {},
        daysWon: {},
        winLoss: {}
      },
      taskHistory: {}
    }
  }

  componentDidMount = async () => {
    this.setState({route: this.props.location.pathname});
    await this.getMonths();
    await this.getTaskHistoryStartDate();
    await this.setNavItems();
    await this.createTaskHistory();
    await this.calculateDailyTaskTotals();
    await this.calculateDailyWinLoss();
    await this.calculateMonthlyDaysWon();
    await this.calculateTasksComplete();
    await this.calculateMonthlyWinLoss();
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if(this.props.tasks !== prevProps.tasks) {
      await this.getTaskHistoryStartDate();
      await this.createTaskHistory();
      await this.calculateDailyTaskTotals();
      await this.calculateDailyWinLoss();
      await this.calculateMonthlyDaysWon();
      await this.calculateTasksComplete();
      await this.calculateMonthlyWinLoss();
    }
    if(prevState.month !== this.state.month) {
      await this.setNavItems();
      await this.createTaskHistory();
      await this.calculateDailyTaskTotals();
      await this.calculateDailyWinLoss();
      await this.calculateMonthlyDaysWon();
      await this.calculateTasksComplete();
      await this.calculateMonthlyWinLoss();
    }
  }

  getMonths = (date) => {
    let currDate;
    if(date === undefined) {
      currDate = new Date();
    } else {
      currDate = new Date(date);
    }
    const firstDay = new Date(currDate.getFullYear(), currDate.getMonth(), 1);
    const lastDay = new Date(currDate.getFullYear(), currDate.getMonth() + 1, 0);
    const earliestDate = new Date(this.state.taskHistoryStartDate);
    const validNavDate = date < new Date() && date >= new Date(earliestDate.getFullYear(), earliestDate.getMonth(), 1);
    if(date === undefined || validNavDate) {
      const prevMonth = new Date(currDate.getFullYear(), currDate.getMonth() - 1, 1);
      const nextMonth = new Date(currDate.getFullYear(), currDate.getMonth() + 1, 1);
      this.setState({month: {
        current: {firstDay: firstDay, lastDay: lastDay},
        previous: prevMonth,
        next: nextMonth
      }});
    }
  }

  setNavItems = () => {
    if(this.state.month.current !== undefined) {
      const { month } = this.state;
      const currMonth = month.current.firstDay.toLocaleString('default', { month: 'long' });
      const currYear = month.current.firstDay.getFullYear();
      const prevMonth = month.previous.toLocaleString('default', { month: 'short' });
      const nextMonth = month.next.toLocaleString('default', { month: 'short' });
      const currDateString = `${currMonth} ${currYear}`;
      const prevDateString = `${prevMonth}`;
      const nextDateString = `${nextMonth}`;
      const earliestDate = new Date(this.state.taskHistoryStartDate);
      let disablePrevLink = '';
      let disableNextLink = '';
      let firstDayOfMonth = new Date(month.current.firstDay);
      if(new Date() < month.current.lastDay) {
        disableNextLink = "Disabled";
      } 
      if(firstDayOfMonth <= earliestDate) {
        disablePrevLink = "Disabled";
      }
      this.setState({navItemsByMonth: 
        {current: currDateString, 
         previous: prevDateString, 
         next: nextDateString,
         disableNextLink: disableNextLink,
         disablePrevLink: disablePrevLink}
        })
    }
  }

  getTaskHistoryStartDate = async () => {
    const { tasks } = this.props;
    const dateJoined = new Date(this.props.user.joined);
    let earliestTask;
    if(tasks.length > 0) {
      tasks.forEach(task => {
        if(!earliestTask) {
          earliestTask = new Date(task.taskDate);
        } else {
          let currTaskDate = new Date(task.taskDate);
          earliestTask = currTaskDate < earliestTask ? currTaskDate : earliestTask;
        }
      });
    } else {
      earliestTask = new Date();
    }
    const historyStartDate = dateJoined <= earliestTask ? dateJoined : earliestTask;
    this.setState({ taskHistoryStartDate: historyStartDate });
  }

  createTaskHistory = () => {
    const { formatDate } = this.props;
    const earliestDate = new Date(this.state.taskHistoryStartDate);
    const today = new Date();
    const firstDayOfMonth = this.state.month.current.firstDay;
    const lastDayOfMonth = this.state.month.current.lastDay;
    const startTaskHistory = earliestDate > firstDayOfMonth ? earliestDate : firstDayOfMonth;
    const endDate = lastDayOfMonth < today ? lastDayOfMonth : today;
    const startDate = new Date(startTaskHistory.getTime());
    let taskHistoryList = {};
    while(startDate <= endDate) {
      let currDate = formatDate(startDate);
      let thisMonth = startDate.toLocaleString('default', { month: 'long' });
      let thisDay = startDate.getDate();
      let thisYear = startDate.getFullYear();
      let dateString = `${thisMonth} ${thisDay}, ${thisYear}`
      taskHistoryList[currDate] = {
        dateId: currDate,
        date: dateString,
        complete: 0,
        numTasks: 0,
        outcome: 'Loss'
      }
      startDate.setDate(startDate.getDate() + 1);
    }
    this.setState({taskHistory: taskHistoryList});
  }

  calculateDailyTaskTotals = () => {
    let taskHistoryList = Object.assign({}, this.state.taskHistory);
    const { tasks } = this.props;
    tasks.forEach(task => {
      if((task.taskDate in taskHistoryList)) {
        let currTaskDate = taskHistoryList[task.taskDate];
        let tasksComplete = task.isComplete ? currTaskDate.complete + 1 : currTaskDate.complete;
        currTaskDate.complete = tasksComplete;
        currTaskDate.numTasks += 1;
      }
    });
    this.setState({taskHistory: taskHistoryList});
  }

  calculateDailyWinLoss = () => {
    const winThreshold = this.props.winThreshold;
    const taskHistory = Object.assign({}, this.state.taskHistory);
    const allDays = Object.keys(taskHistory);
    allDays.forEach(day => {
      let tasksComplete = taskHistory[day].complete;
      let numTasks = taskHistory[day].numTasks;
      if(tasksComplete === numTasks && numTasks >= winThreshold) {
        taskHistory[day].outcome = 'Win';
      } else if(day === this.props.formatDate(new Date())) {
        taskHistory[day].outcome = 'WIP';
      }
    });
    this.setState({taskHistory: taskHistory});
  }

  calculateMonthlyDaysWon = () => {
    let daysWon = 0;
    let totalDays = 0;
    const allDays = Object.keys(this.state.taskHistory);
    const dashboardStats = Object.assign({}, this.state.dashboardStats);
    allDays.forEach(day => {
      totalDays+=1;
      if(this.state.taskHistory[day].outcome === 'Win') {
        daysWon+=1;
      }
    });
    const winRate = Math.round(daysWon / totalDays * 100);
    dashboardStats.daysWon.numDays = daysWon;
    dashboardStats.daysWon.winRate = winRate;
    this.setState({dashboardStats: dashboardStats});
  }

  calculateTasksComplete = () => {
    let tasksComplete = 0;
    let totalTasks = 0;
    const allDays = Object.keys(this.state.taskHistory);
    const dashboardStats = Object.assign({}, this.state.dashboardStats);
    allDays.forEach(day => {
      tasksComplete += this.state.taskHistory[day].complete;
      totalTasks += this.state.taskHistory[day].numTasks;
      });
    let completionRate = Math.round(tasksComplete / totalTasks * 100);
    completionRate = isNaN(completionRate) ? 0 : completionRate;
    dashboardStats.taskMetrics.tasksComplete = tasksComplete;
    dashboardStats.taskMetrics.completionRate = completionRate;
    this.setState({dashboardStats: dashboardStats});
  }

  calculateMonthlyWinLoss = () => {
    const currMonth = this.state.month.current.firstDay.toLocaleString('default', { month: 'long' });
    const dashboardStats = Object.assign({}, this.state.dashboardStats);
    let winLoss = 'Loss';
    let message = `You Lost ${currMonth}`;
    const daysWon = this.state.dashboardStats.daysWon.numDays;
    const numDays = Object.keys(this.state.taskHistory).length;
    let winRateForMonth = daysWon / numDays * 100;
    winRateForMonth = isNaN(winRateForMonth) ? 0 : winRateForMonth;
    document.getElementById('Monthly-outcome').classList.add('Default-outcome');
    if(winRateForMonth >= 50 && new Date() > this.state.month.current.lastDay) {
      winLoss = 'Win';
      message = "Dues Paid"
      document.getElementById('Monthly-outcome').classList.remove('Default-outcome');
    } else if(new Date() < this.state.month.current.lastDay) {
      winLoss = "Wip";
      message = winRateForMonth >= 50 ? "Current Trajectory: W" : "Current Trajectory: L";
    }
    dashboardStats.winLoss.outcome = winLoss;
    dashboardStats.winLoss.message = message;
    this.setState({dashboardStats: dashboardStats});
  }

  render = () => {
    const { user } = this.props;
    return (
      <div>
        <Header 
          user={user}
          throwBanner={this.props.throwBanner}
          logUserOut={this.props.logUserOut}
          toggleMobileMenu={this.props.toggleMobileMenu}
          />
        <div className="Content">
          <Nav />
          <div className="Main">
            <div className="Container">
              <div className="Card">
                <div className="Mobile-header-bg">
                  <div className="Mobile-header-wrapper">
                    <div className="Mobile-pl-icon">
                      <Link to='/'>
                        <Logo />
                      </Link>
                    </div>
                      <div className="Page-title-wrapper">
                      <p className="Page-title">Stats</p>
                    </div>
                    <div className="Mobile-menu-link" onClick={this.props.toggleMobileMenu}>
                      <span className="Mobile-menu-text">Menu</span>
                      <MenuIcon className="Mobile-menu-icon" />
                    </div>
                  </div>
                    <DateNav 
                      month={this.state.month} 
                      navItemsByMonth={this.state.navItemsByMonth} 
                      getMonths={this.getMonths}/>
                </div>
                <div className="Phablet-task-history-bg">
                <Dashboard dashboardStats={this.state.dashboardStats}/>
                <TaskHistory state={this.state}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Stats);