import React from 'react';
import './TaskList.css';
import Checkbox from '../Checkbox/Checkbox';
import { ReactComponent as CommentIcon} from './comment-icon.svg';


const TaskList = ({ taskList, onCheckedTask, onEditTask, onDeleteTask, handleOpenModal, commentTotals }) => {
  
  let orderedTasks = [...taskList];
  orderedTasks.sort((a, b) => a.taskIndex > b.taskIndex ? 1 : -1);
  return (
      <div className="Task-list">
        {orderedTasks.slice(0).reverse().map((task, i) => (
          <div id={`Task-${task.taskIndex}`} className="List-item" key={task.id}>
            <div className="Task-contents">
              <Checkbox date={task.taskDate} checked={task.isComplete} onCheck={onCheckedTask} key={i} />
              <p className={`Task ${task.isComplete ? 'Complete' : ''} ${new Date(task.taskDate) > new Date() ? 'Disabled' : ''}`}>{task.data}</p>
            </div>
            <div className="Icons-container">
              <div className="Icon-wrapper" onClick={handleOpenModal}>
                <CommentIcon className="Comment-icon" />
                <p className="Comment-total">{commentTotals[task.id] || 0}</p>
              </div>
              <div className="Icon-wrapper" onClick={onEditTask} disabled={task.isComplete}>
                <i className="far fa-edit Task-icon"></i>
              </div>
              <div className="Icon-wrapper" onClick={onDeleteTask}>
                <i className="far fa-trash-alt Task-icon"></i>
              </div>
            </div>
          </div>
        ))}
      </div>
  );
}

export default TaskList;