import React, { Component } from 'react';
import logo from '../powerlist-light-bg-final.svg';
import LoginForm from '../components/Forms/LoginForm';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginEmailAddress: '',
      loginPassword: ''
    }
  }

  onEmailChange = (e) => {
    this.setState({loginEmailAddress: e.target.value});
  }

  onPasswordChange = (e) => {
    this.setState({loginPassword: e.target.value});
  }

  onLoginSubmit = async () => {
    try {
      const { isValidEmail, isValidPassword, getAuthToken } = this.props;
      const email = isValidEmail(this.state.loginEmailAddress);
      const password = isValidPassword(this.state.loginPassword);
      if(email.isValid && password.isValid) {
        const response = await fetch(`${this.props.url}/user/login`, {
          method: 'post', 
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ email: email.value, password: password.value })
        });
        const responseData = await response.json();
        if(response.status === 400) {
          throw new Error(responseData);
        }
        await localStorage.setItem('pl_auth_token', JSON.stringify(responseData.token));
        await getAuthToken();
      } else {
        if(!email.isValid && password.isValid) {
          throw new Error('Please enter a valid email');
        }
        if(email.isValid && !password.isValid) {
          throw new Error('Please enter a valid password (8-32 characters)');
        }
        if(!email.isValid && !password.isValid) {
          throw new Error('Please enter a valid email and password');
        }
      }
    } catch (e) {
      this.props.throwBanner('Alert', e.message);
    }
  }
  

  render = () => {
    return(
      <div>
        <div className="Form-container">
          <div className="Card">
          <img src={logo} alt="Power List Logo" className="Signup-login-logo"/>
          <LoginForm 
            onEmailChange={this.onEmailChange} 
            onPasswordChange={this.onPasswordChange} 
            onLoginSubmit={this.onLoginSubmit}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default Login;