import React from 'react';
import { NavLink } from 'react-router-dom';
import './Nav.css';
import { ReactComponent as Logo} from './powerlist-menu-icon.svg';

const Nav = () => {
  return(
    <div className="Nav-container">
      <ul className="Nav">
        <NavLink exact to='/' className="Nav-link" activeClassName='active'>
          <Logo className="pl-nav-icon"/>
          <li>Power List</li>
        </NavLink>
        <NavLink exact to='/stats' className="Nav-link" activeClassName='active'>
          <i className="far fa-chart-bar"></i>
          <li>Stats</li>
        </NavLink>
        <NavLink exact to='/rules' className="Nav-link" activeClassName='active'>
          <i className="fas fa-balance-scale"></i>
          <li>Rules</li>
        </NavLink>
      </ul>
    </div>
  );
}

export default Nav;