import React from 'react';
import validator from 'validator';
import './CommentInput.css';

const CommentInput = ({ editState, commentInput, onInputChange, onSubmitNewComment, onUpdateComment, onClearEditState }) => {
  const activeBtn = validator.trim(commentInput).length > 0 ? 'Active' : '';
  if(editState) {
    return (
      <div className="Input-component Comment-component">
        <textarea className="Comment-input" type="text" placeholder="Enter a Note" onChange={onInputChange}/>
        <button className="Task-btn Save Comment-submit" type="submit" onClick={onUpdateComment}>Update</button>
        <button className="Task-btn Cancel Comment-cancel"  onClick={onClearEditState}>Cancel</button>
      </div>
    )
  } else {
    return (
      <div className="Input-component Comment-component">
        <textarea className="Comment-input" type="text" placeholder="Enter a Note" onChange={onInputChange}/>
        <button className={`Task-btn Comment-submit ${activeBtn}`} type="submit" onClick={onSubmitNewComment}>Save</button>
      </div>
    )
  }
  
}

export default CommentInput;